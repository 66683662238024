  /* body{
    background-color: white!important;
  } */
  /* hr{
    color:white!important;
  } */
   body{
    margin: 0px;
   }
  .nav-space{
    height: 50px;
  }
  .sector-page, .solution-page, .about-page, .gallery-page{
    margin-top:45px!important;
  }
  .navbar-brand > img{
    margin-left: 50px;
  }
  hr{
    color:black!important;
  }
  p{
    color: black;
    font-size:larger;
  }
  .nav-p{
    color: rgb(40, 2, 77);
  }
  ui li{
    list-style-type: none;
  }
  .cards {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 10px;
    align-content: center;
    justify-content: space-evenly;
  }
  
  .card {
    margin: 3px;
    margin-bottom: 10px;
    height: 200px;
    width:100%;
    background-color: white!important;
    color:white!important;
  }
  .more{
    color: white!important;
    text-decoration: none;
  }
  .card-sector {
    min-width:30%!important;
    position: relative;
    padding: 1.5rem;
    border-radius: 5px;
    border: 1px solid rgb(10, 221, 245);
    margin:1em;
  }
  .card-sector-two {
    /* margin: 1rem; */
    min-width:30%!important;
    position: relative;
    /* padding: 1.5rem; */
    /* border-radius: 5px; */
    /* border: 1px solid rgb(10, 221, 245); */
    color: white!important;
    background-color:white!important;
    border-radius: 0.5em 0.5em 0.5em 0.5em;
    box-shadow: 0.5em 0.5em 0.5em 0.5em;
    margin:1em;
  }
  .card-sector-two p{
    padding: 0em 1em;
  }
  .card-sector:hover{
    transition: all 0.5s ease-out;
    background:linear-gradient(45deg,rgb(200, 200, 223),white)
  }
  .card-sector-two:hover{
    transition: all 0.5s ease-out;
    background:linear-gradient(45deg,rgb(200, 200, 223),white)
  }
  .sectorImage{
    width: 100%;
    height: 250px;
    border-radius: 0.5em 0.5em 0em 0em;
  }
  .card-sector-two card-body{
    position:absolute;
  }
  .sector-heading{
    position: relative;
    bottom: 5rem; 
    display: flex;
    justify-content: center!important;
    /* align-items: center; */
    /* left: auto;
    right: auto; */
    max-width: 100%;
    /* left: 4rem; */
    /* margin: 0rem auto 5rem auto!important; */
    /* text-justify: auto!important; */
    /* text-align: center!important; */
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: larger;
    /* width: max-content !important; */
    background:rgba(0, 0, 0, 0.6) !important;
    /* height: 80px!important; */
    /* float: inline-end!important; */
  }
  .sector-heading p{
    position: absolute;
    color:white;
    text-align: center!important;
    background:rgba(0, 0, 0, 0.6) !important;
    width: 100%;
    height: 80px!important;
    font-size:larger;
    /* left: 40%!important; */
  }
  .sector-heading h2{
    position: absolute;
    color:white;
    text-align: center!important;
    background:rgba(0, 0, 0, 0.6) !important;
    width: 100%;
    height: 80px!important;
    font-family: Arial;
    font-size:larger;
    font-weight:bold;
    /* left: 40%!important; */
  }
  /* .card-body h5{
    position: absolute!important;
    text-align: center!important;
    top: 1.1rem;
    background:rgba(0, 0, 0, 0.7) ;
    color: white;
    font-weight: bold;
    width: max-content !important;    
  } */
  .sol-card-body{
    position: relative;
    margin:0px!important;
    padding: 15px!important;
  }
  .card-form {
    margin: 1rem;
    margin-top: 45px;
    width:100%;
    padding: 1.5rem;
    border-radius: 5px;
    border: 1px solid rgb(10, 221, 245);
  }
 
  .card-sector-foo {
    width:25%;
    padding: 1rem;
  }
  .card-sector-foo>h5 {
    color: orange;
  }
  h5{
    text-align: center;
    text-decoration: none;
    color: rgb(8, 8, 114);
    font-size: x-large!important;
    font-family: Nexa;
  }
  h2{
    text-align: center;
    text-decoration: none;
    color: rgb(8, 8, 114);
    font-size: x-large!important;
    font-family: Nexa;
  }
  h1{
    font-size: xx-large!important;
    text-align: center;
    text-decoration: none;
    color: rgb(8, 8, 114);
    font-family: Nexa;
  }
  .whiteheading{
    color: rgb(30, 4, 65);
  }
  li{
    text-align: center;
  }

  .icon service{
    color:rgba(166, 166, 9, 0.927);
  }
  .icons-card-body{
    text-align: center; 
  } 
  .poweredBy{
    position: relative;
    align-items: end;
    text-align: end;
    left: 10px;
  }
  .poweredBy h5{
    text-align: end;
  }


/* button{
  margin: 0px;
  padding: 0px;
  position: relative;
  bottom: 0px;
  right: 0px;
} */
/* .btn btn-primary{
  margin: 0px;
  padding: 0px;
} */
button.btn.btn-primary{
  background-color: rgb(8, 8, 114)!important;
  margin: 0rem 1rem 1rem 0rem!important;
  height: 40px;
  text-align:center;
  /* float: inline-end!important; */
}

button.btn.btn-primary p{
  color: white!important;
}
b{
  color:rgb(8, 8, 114);
}
.card-get-in {
  padding: 1rem;
  margin:1rem;
  margin-top:50px!important;
  border-radius: 5px;
  border: 1px solid rgb(10, 222, 245);
}

li>a{
  text-decoration: none!important;
  font-size: small!important;
  color:whitesmoke;
}
li>span>a{
  padding: 10px;
  color:whitesmoke;
}
.list-group-item{
  align-items: center;
}

.galleryImage{
  width: 100%;
  border-radius: 1em 1em 0em 0em;
}
.aboutImage{
  width: 100%;
}
.card-gallery{
  border-radius: 1em 1em 1em 1em;
  margin: 1rem;
  box-shadow: 1em 1em 1em 1em;
}
.card-about{
  margin: 1em;
  box-shadow: 1em 1em 1em 1em;
  padding: 1rem;
}
.aboutImage{
  width: 100%;
}
.card-gallery p{
  padding: 0em 1em;
}
p .descBold{
  font-weight: bold;
}
.copy-right{
  text-align: center;
  color:whitesmoke;
  font-size: smaller;
}
.carousel {
  width:50%;
  height:auto;
  margin:auto;
}

.carousel-item {
  background-color: rgb(59, 139, 219);
  width: 400px;
  height:350px;
  margin: auto; 
  padding:1.5rem;
  color: white !important;
}
.carousel-body{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center!important;
}
.carousel-body p{
  color: white;
}
.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: flex!important;
}

.auth-dev{
  display: inline;
}

.icon-span{
  margin-right: 1rem;
}
.icon-span-sec{
  margin-right: 0.5rem;
}
.org{
  color:rgba(166, 166, 9, 0.927); 
  font-size: large;
  /* font-weight: bolder; */
}
.footer{
  background-color: rgb(5, 5, 75);
  width:100%;
  position: absolute;
  margin-left: 0!important;
}
.aboutButton{
  position: relative;
  margin: 1rem;
  float:inline-end
}
.galleryInImage{
  width: 100%;
  height:300px;
}
a{
  text-decoration: none;
}
.btn btn-primary{
  float:inline-end!important;
}
.btn btn-primary p{
  color: white!important;
}
.more-sector{
  position:absolute;
  bottom: 0px!important;
  right:0px!important;
  float: inline-end!important;
}
.icons-h5{
  color: rgb(8, 8, 114);
}
.more-sector p{
  color: white;
}
.connect p{
  color:white;
}
.card-gallery card-body{
  position:absolute;
}
.galleryImageName{
  position: relative;
  bottom: 5rem; 
  display: flex;
  justify-content: center!important;
  width: 100%;
  color: white;
  font-weight: bold;
  text-align: center;
  background:rgba(0, 0, 0, 0.6) !important;
}
.galleryImageName p{
  position: absolute;
  color:white;
  text-align: center!important;
  background:rgba(0, 0, 0, 0.6) !important;
  width: 100%;
  font-size: x;
  height: 80px!important;
}
.subservice-name{
  color: rgb(17, 47, 242);
  /* color: rgb(233, 214, 43); */
}
.about-logo{
  width:150px;
  height:100px;
}
/* @media only screen and (max-width: 700px) and (min-width: 450.1px){
  .card-sector-foo {
    width:50%;
    padding: 1rem;
  }
  .carousel {
    width:100%;
    height:auto;
    margin:auto;
  }
  
} */

@media (max-width:450px) {
  body {
    margin: 0px;
  }
  .sector-page, .solution-page{
    margin-top:50px!important;
  }

  .sectorCopyContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-space{
    height: 40px;
  }
  .navbar{
    height: 60px;
  }
  .navbar-brand > img{
    margin-left: 30px;
    height: 40px;
    width: 60px;
  }
  .nav-link{
    text-align:center;
    align-items: center;
    margin-left: 200px;
    background-color: white;
  }
  .cards {
    margin: 0px;
  }
  .card  {
    width: 100px;
    height:100px;
    padding: 0px!important;
    /* margin: 5px!important; */
  }
  
  .card-sector {
    margin: 5px;
    width:100%;
    padding: 1.0rem 1.0rem 0rem 1.0rem;
    border-radius: 5px;
  }    

  #sectorCopyRow{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .card-sector-two {
    position: relative;
    margin: 1rem 0rem;
    /*width:100%;
    padding: 0.5rem;
    border-radius: 5px; */
    background-color:white!important;
  }
  .sector-heading{
    position: relative;
    bottom: 2.6rem;
  }
  .sector-heading p{
    font-size:20px;
    height: 40px!important;
    text-align:center!important;
  }        
  .sector-heading h2{
    font-size:20px;
    height: 40px!important;
    text-align:center!important;
  }        
  .card-form {
    margin: 50px 10px 30px 10px;
    /* margin-top: 50px!important; */
    width:100%;
    padding: 1rem;
    border-radius: 5px;
  }    
  .card-body{
    position: relative;
    margin:0px!important;
    padding: 0px!important;
  }
  .solicon  {
    width: 100px;
    height:100px;
    padding: 0px!important;
    /* margin: 5px!important; */
  }

  .sol-card-body{
    position: relative;
    margin:0px!important;
    padding: 6px!important;
  }
  
  h5{
    font-size:small!important;
    font-weight:bold;
  }
  .icons-h5{
    font-size:x-small!important;
    color: rgb(8, 8, 114);
  }
  .card-about{
    margin: 0.5rem;
    box-shadow: 1em 1em 1em 1em;
    padding: 0.5rem;
    width: 40%;
    height: 200px;
  }
  .aboutImage{
    width:150;
    height:100px;
  }
  svg{
    width: 20px;
    height: 20px;
    align-content: center;
  }
  .icons-card-body{
    text-align: center;
  } 
  p{
    font-size:16px;
  }
  sector-description{
    font-size: 14px!important;
  }
  label{
    font-size:large;
  }
  input{
    font-size:large;
  }
  input::placeholder {
    font-size:small;
  }
  textarea::placeholder {
    font-size:small;
  }
  .card-sector-foo {
    width:50%;
    padding: 1rem;
  }
  li>a{
    font-size: xx-small!important;
  }
  .carousel {
    width:100%;
    height:auto;
    margin:auto;
  }
  .carousel-caption h5{
    color:white;
    font-size:x-small!important;
    font-weight:bold
  }
  .carousel-caption p{
    color:white;
    font-size:xx-small!important;
    font-weight:bold;
  }

  .carousel-item {
    background-color:  rgb(59, 139, 219);
    width: 200px;
    height:150px;
    margin: auto; 
    padding:1.5rem;
    color: white !important;
  }
  .carousel-body p{
    font-size: x-small;
  }

  .navbar-nav .dropdown-menu {
    position: inherit;
  }
  .dropdown-toggle show nav-link{
    font-size:xx-small;
  }

  /* .card-gallery{
    width: 44%;
    height: auto;
  } */
  .galleryInImage{
    width: 310px;
    height:350px;
  }
  .galleryImageName p{
    font-size: larger;
  }
  h1{
    font-size: 20px!important;
    text-align: center;
    text-decoration: none;
    color: rgb(8, 8, 114);
    font-family: Nexa;
  }
  h2{
    font-size: 20px!important;
    text-align: center;
    text-decoration: none;
    color: rgb(8, 8, 114);
    font-family: Nexa;
  }
  .sectorImage{
    width: 100%;
    height: 200px;
  }
  .more-sector{
    position:absolute;
    bottom: 0px!important;
    right:-0px!important;
    float: inline-end!important;
  }
  .poweredBy{
    position: relative;
    align-items: end;
    text-align: end;
  }
  .poweredBy h5{
    text-align: end;
  }
  .poweredBy img{
    height: auto;
    width:100px;
  }
  .about-icon svg{
    height: 50px;
    width: auto;
    align-self: center!important;
  }
  .customized-h2{
    font-size: 13px!important;
    font-weight: bold;
  }
  .about-logo{
    width:100px;
    height:70px;
  }
  .copy-right{
    font-size: 12px!important;;
  }
}